<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron técnicos asignados"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :toolbar-options="toolbarOptions"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="idparte_trabajo_tecnico"
      @click-toolbar-option="clickToolbarOption"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <parte-trabajo-tecnico-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :has-perm-edit="hasViewPerm(permissions.partes.annadirTecnico) && !descargado"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
    <parte-trabajo-tecnico-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-fab-button
      v-if="hasViewPerm(permissions.partes.annadirTecnico) && !descargado"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './ParteTrabajoTecnicoListData'
import ParteTrabajoTecnicoListItem from './components/ParteTrabajoTecnicoListItem'
import ParteTrabajoTecnicoListLegend from './components/ParteTrabajoTecnicoListLegend'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesParteTrabajoTecnicoList'

export default {
  components: {
    ParteTrabajoTecnicoListItem,
    ParteTrabajoTecnicoListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      showingDialogs: {
        legends: false,
      },
      descargado: false,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = `Técnicos asignados al parte de trabajo Nº${this.routeParams.idparte_trabajo}`
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectParteTrabajoTecnico(
          this,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idparte_trabajo
        )
        await this.loadItemsBase(dataset, metadata)
        const resp = await Data.selectDetails(this, this.routeParams.idparte_trabajo)
        this.descargado = resp.data.parteTrabajoDescargado.result
        if (this.descargado) {
          this.$alert.showSnackbarWarning('Parte descargado por otro técnico. No se puede modificar')
        }
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectParteTrabajoTecnicoRows(this, dirty.modified)
        await this.reloadItemsBase(dataset, dirty, 'idparte_trabajo_tecnico')
      } finally {
        this.loadingData = false
      }
    },
    clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'partes__tecnicosasignados__parte-trabajo-tecnico-add',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
        },
      })
    },
    async clickDelete (data) {
      let mensaje
      if (data.item.tiene_tiempo_trabajado) {
        mensaje = `${data.item.nombre_y_apellido} tiene tiempo trabajado en este parte. ¿Deseas continuar?`
      } else {
        mensaje = `¿Deseas quitar a ${data.item.nombre_y_apellido} del parte de trabajo?`
      }
      const res = await this.$alert.showConfirm(mensaje)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await Data.deleteParteTrabajoTecnico(this, data.item.idparte_trabajo_tecnico)
          await this.dispatchStore('deleteItem', data.index)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.parteTrabajo, this.routeParams.idparte_trabajo)
        } finally {
          this.$loading.hide()
        }
      }
    },
    clickListItem (item) {
      // se recarga la lista completa porque puede cambiar el técnico principal
      this.$appRouter.push({
        name: 'partes__tecnicosasignados__parte-trabajo-tecnico-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idparte_trabajo_tecnico: item.data.idparte_trabajo_tecnico,
        },

      })
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      }
    },
  },
}
</script>
