<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.conTiempoTrabajado"
          class="ml-1 mt-1"
          small
          color="red"
          label
          dark
        >
          <v-icon
            left
            small
          >
            {{ $vuetify.icons.values.tiempoTrabajado }}
          </v-icon>
          Con tiempo trabajado
        </v-chip>
        <v-chip
          v-if="formattedItem.principal"
          :color="colorPrincipal"
          dark
          label
          small
          class="ml-1 mt-1"
        >
          Principal
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn
        v-if="formattedItem.editable"
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { PARTE_TRABAJO_TECNICO } from '@/utils/consts'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    hasPermEdit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      colorPrincipal: PARTE_TRABAJO_TECNICO.colores.principal
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.nombre_y_apellido
      item.subtitle = ''
      item.avatarColor = 'info'
      item.avatar = item.codigo
      item.editable = this.hasPermEdit && !item.principal
      item.conTiempoTrabajado = item.tiene_tiempo_trabajado
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
